import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { from } from 'rxjs';
import { SnackMessageService } from './snackMessage.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  times: number[] = [];

  constructor(private snackMessageService: SnackMessageService) {}

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  prepareCards(width) {
    switch (true) {
      case width > 320 && width <= 580:
        this.times = [1];
        break;
      case width > 580 && width <= 1023:
        this.times = [1, 2];
        break;
      case width > 1023 && width <= 1280:
        this.times = [1, 2, 3];
        break;
      case width > 1280 && width <= 1440:
        this.times = [1, 2, 3];
        break;
      case width > 1440 && width <= 1730:
        this.times = [1, 2, 3, 4];
        break;
      case width > 1730 && width <= 1920:
        this.times = [1, 2, 3, 4];
        break;
      case width > 1920 && width <= 2660:
        this.times = [1, 2, 3, 4, 5];
        break;
      case width >= 2660:
        this.times = [1, 2, 3, 4, 5, 6];
        break;
      default:
        this.times = [];
        break;
    }
    return this.times;
  }

  randomNumber(data, numberRandom, maxNumberRandom?) {
    if (maxNumberRandom === undefined) {
      maxNumberRandom = 10;
    }
    const max = data.length - 1;
    const min = 0;
    for (var i = 0; numberRandom.length < maxNumberRandom; i++) {
      let select = Math.floor(Math.random() * (max - min) + min);
      if (numberRandom.includes(select)) {
        select = Math.floor(Math.random() * (max - min) + min);
      } else {
        numberRandom.push(select);
      }
    }
    return numberRandom;
  }

  conversorString(texto: any) {
    return Buffer.from(texto, 'base64').toString();
  }

  conversorNumberBase64(texto: any) {
    return Buffer.from(texto, 'base64');
  }

  autoGrowTextArea(textarea: any) {
    textarea.target.style.height = '0px';
    textarea.target.style.height = textarea.target.scrollHeight + 25 + 'px';
  }

  conversorBase64(texto: any) {
    return Buffer.from(texto).toString('base64');
  }

  removeSpacesAndInsertScript(string) {
    return string.replace(/[\s_.]/g, '-');
  }

  copyToClipBoard(value) {
    from(navigator.clipboard.writeText(value)).subscribe((res) => {
      this.snackMessageService.sendMessage('Value copied to clipboard');
    });
  }

  filterCategoryApplicationsPrivateAndEnabled(categoryApplicationsList) {
    let _privateAndEnabledApps = [];
    categoryApplicationsList.forEach((application) => {
      if (!application.isPublic && application.enabled) {
        _privateAndEnabledApps.push(application);
      }
    });
    return _privateAndEnabledApps;
  }

  addTagsList(appsList) {
    let _appsList = [];
    appsList.forEach((app, index) => {
      _appsList.push(app);
      let _dataTagsList = [];
      if (app.categories.find((category) => category.type === 3) !== undefined) {
        if (!_dataTagsList.includes('Best Seller')) {
          _dataTagsList.push('Best Seller');
        }
      }
      if (app.categories.find((category) => category.type === 2) !== undefined) {
        if (!_dataTagsList.includes('In library')) {
          _dataTagsList.push('In library');
        }
      }

      if (
        app.categories.find((category) => category.type === 4) !== undefined &&
        app.price !== undefined &&
        app.price.length > 0 &&
        app.price[0].value === 0
      ) {
        if (!_dataTagsList.includes('Free')) {
          _dataTagsList.push('Free');
        }
      }
      _appsList[index].dataTagList = _dataTagsList;
    });

    return _appsList;
  }
}