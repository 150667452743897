<div class="{{ theme }} {{ className }}" id="home-public">
  @if (isLoading) {
    <!--Spinner
    <div class="{{ theme }} spinner">
      <mat-spinner
        class="{{ theme }}"
        color="purple"
        [diameter]="150"
        [strokeWidth]="10"></mat-spinner>
    </div>
    -->
    <!-- Featured -->
    <ng-container>
      <div class="{{ theme }} {{ className }}__featured-wrapper">
        <div class="{{ theme }} {{ className }}__category-wrapper featured">
          <h3 class="{{ theme }} {{ className }}__featured-title">
            {{ 'Featured' }}
          </h3>
        </div>
        <div class="{{ theme }} {{ className }}__featured-list-wrapper">
          <ul class="{{ theme }} {{ className }}__featured-list">
            @for (skeleton of skeletonFeatured; track $index) {
              <li
                *ngIf="
                  width >= 3800
                    ? $index <= 4
                    : width < 3800 && width >= 1800
                      ? $index <= 3
                      : $index <= 2
                "
                class="{{ theme }} {{ className }}__featured-list-item">
                <skeleton-card [type]="'vertical'" [width]="width" [size]="'big'"></skeleton-card>
              </li>
            }
          </ul>
        </div>
      </div>
    </ng-container>
    <!-- Banner -->
    <div class="{{ theme }} {{ className }}__banner">
      <div class="{{ className }}__skeleton vertical-banner"></div>
    </div>
    <!-- Apps public -->
    <skeleton-cards-list
      [title1]="''"
      [title2]="'Suggested Apps'"
      [context]="'/public/'"></skeleton-cards-list>
    <!-- Banner -->
    <div class="{{ theme }} {{ className }}__banner">
      <div class="{{ className }}__skeleton vertical-banner"></div>
    </div>
  }
  @if (!isLoading) {
    @if (dataCategoryFeatured !== undefined && dataCategoryFeaturedApplications.length > 0) {
      <h3 class="{{ theme }} {{ className }}__categoryFeatured-title">
        {{ dataCategoryFeatured.name }}
      </h3>
      <div class="{{ className }}__categoryFeatured">
        <ul class="{{ className }}__categoryFeatured-list">
          @for (app of dataCategoryFeaturedApplications; track $index) {
            <li
              (click)="openModalContact($event)"
              class="{{ className }}__categoryFeatured-list-item">
              <app-card-vertical
                [_data]="app"
                [categoryFeaturedId]="categoryFeaturedId"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryPurchasedId]="categoryPurchasedId"
                [categorySpecialId]="categorySpecialId"
                [context]="'/public/'"
                [isDesktop]="isDesktop"
                [showButtons]="false"
                [showTagsList]="false"
                [showText]="showText"
                [theme]="theme"
                [width]="width"></app-card-vertical>
            </li>
          }
        </ul>
      </div>
    }
    @if (dataBanner !== undefined) {
      <div class="{{ theme }} {{ className }}__banner">
        <app-banner
          (contactUsButtonEvent)="openModalContact($event)"
          [_data]="dataBanner"
          [isDesktop]="isDesktop"
          [theme]="theme"
          [width]="width"></app-banner>
      </div>
    }
    @if (dataApplications !== undefined) {
      <div class="{{ theme }} {{ className }}__category">
        @if (dataApplications.length > 0) {
          <ul class="{{ theme }} {{ className }}__category-list">
            @for (app of dataApplications; track $index) {
              <li class="{{ theme }} {{ className }}__category-list-item">
                <app-card-vertical
                  [_data]="app"
                  [categoryFeaturedId]="categoryFeaturedId"
                  [categoryMostPurchasedId]="categoryMostPurchasedId"
                  [categoryPurchasedId]="categoryPurchasedId"
                  [categorySpecialId]="categorySpecialId"
                  [context]="'/public/'"
                  [isDesktop]="isDesktop"
                  [showButtons]="false"
                  [showTagsList]="false"
                  [showText]="showText"
                  [theme]="theme"
                  [width]="width"></app-card-vertical>
                <div
                  (click)="openModalContact($event)"
                  *ngIf="showOverlay"
                  class="{{ theme }} {{ className }}__overlay"
                  id="card-vertical-overlay-{{
                    utilsService.removeSpacesAndInsertScript(app.name)
                  }}"></div>
              </li>
            }
          </ul>
        }
      </div>
    }
    @if (dataBanner !== undefined) {
      <div class="{{ theme }} {{ className }}__banner last">
        <app-banner
          (contactUsButtonEvent)="openModalContact($event)"
          [_data]="dataBanner"
          [isDesktop]="isDesktop"
          [theme]="theme"
          [width]="width"></app-banner>
      </div>
    }
  }
</div>