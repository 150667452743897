import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsSearchObservableService {
  searchValue: Observable<string>;

  constructor() {
    this.searchValue = new BehaviorSubject('');
  }

  setSearchValue(value: string) {
    console.log('setSearchValue', value);
    (this.searchValue as BehaviorSubject<string>).next(value);
  }

  getSearchValue(): Observable<string> {
    return this.searchValue;
  }
}