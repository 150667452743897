<div #scrollPage class="{{ theme }} {{ className }}">
  <app-category-detail
    (closeMenuEmitter)="closeMenu($event)"
    (refreshDataEmitter)="refreshData()"
    [buttonText]="buttonText"
    [buttonUrl]="buttonUrl"
    [categoryFeaturedId]="categoryFeaturedId"
    [categoryFeatured]="categoryFeatured"
    [categoryMostPurchasedId]="categoryMostPurchasedId"
    [categoryMostPurchased]="categoryMostPurchased"
    [categoryName]="this.dataCategory['name']"
    [categoryPurchasedId]="categoryPurchasedId"
    [categoryPurchased]="categoryPurchased"
    [categorySpecialId]="categorySpecialId"
    [categorySpecial]="categorySpecial"
    [categorySuggestedId]="categorySuggestedId"
    [categorySuggested]="categorySuggested"
    [context]="'/private/category-details'"
    [description]="description"
    [img]="img"
    [isDesktop]="isDesktop"
    [isLoading]="isLoading"
    [itemsList]="results"
    [showText]="showText"
    [theme]="theme"
    [title]="title"
    [width]="width">
  </app-category-detail>
</div>