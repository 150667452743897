import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ApplicationModel,
  BannerModel,
  CategoryModel,
  CommonLabels
} from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { ModalMessageComponent } from '../../../../common/components/modal-message/modal-message.component';
import { MatDialog } from '@angular/material/dialog';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { ApplicationsService } from '../../../../common/services/applications.service';
import { SortCriteria } from 'app/common/utils/sort-by.pipe';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';
import { AuthService } from '../../../../common/services/auth.service';
import { UtilsService } from '../../../../common/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CategoriesService } from '../../../../common/services/categories.service';

@Component({
  selector: 'app-home-public',
  templateUrl: './home-public.component.html',
  styleUrls: ['./home-public.component.scss']
})
export class HomePublicComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() openModalContactEmitter: EventEmitter<any> = new EventEmitter();
  theme = 'marketplace-default';
  className = 'app-home-public';
  isDesktop: boolean = false;
  width: number = 0;
  labels: CommonLabels = commonLabels;
  isLoading = true;
  showOverlay = true;
  // Mocks
  dataBanner: BannerModel = {
    title1: 'Want to start deploying at the Edge?',
    title2: undefined,
    text: 'Experience unparalleled performance and efficiency. Begin your edge deployment journey today!',
    buttonId: 'button-contact-us',
    button: 'Contact us',
    srcImg: 'assets/images/banners/banner.svg',
    srcImgMobile: 'assets/images/banners/banner.svg'
  };
  url = '';
  categoryFeaturedId: string; //Featured
  categorySpecialId: string; //Barbara Basics
  categoryPurchasedId: string; //Already in library
  categoryMostPurchasedId: string; //Most used
  categoriesPublicList: CategoryModel[] = [];
  dataCategoryFeatured: CategoryModel = undefined;
  dataCategoryFeaturedApplications: ApplicationModel[] = [];
  dataApplications: ApplicationModel[] = [];
  showText: boolean = true;
  menuState: string;
  skeletonFeatured = Array(5).fill(0);
  arraySubscriptions: Subscription[] = [];
  router = inject(ActivatedRoute);
  public criteria: SortCriteria;

  constructor(
    private readonly resizeWindowService: ResizeWindowService,
    public matDialog: MatDialog,
    private authService: AuthService,
    private categoriesService: CategoriesService,
    private categoriesStoreService: CategoriesStoreService,
    private snackMessageService: SnackMessageService,
    private applicationsService: ApplicationsService,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    //subscribe to the window resize event
    this.isLoading = true;
    this.categoriesStoreService.setStateMenu('close');
    this.initSubscriptions();
    this.goTop();
  }

  initSubscriptions() {
    this.initRouterUrl();
    this.initDesktop();
    this.initWidth();
    this.initStateMenu();
    this.initAllCategories();
    this.initCategoryFeatured();
  }

  initRouterUrl() {
    this.arraySubscriptions.push(
      this.router.url.subscribe({
        next: (urlSegments) => {
          this.url = urlSegments[urlSegments.length - 1].path;
          this.applicationsService.getApplicationByIdP(this.url).subscribe({
            next: (data) => {
              this.dataApplications = data.sort((a, b) => {
                if (a.order > b.order) {
                  return -1;
                }
                if (a.order < b.order) {
                  return 1;
                }
                return 0;
              });
            },
            error: (error) => {
              this.isLoading = false;
              if (error.error.response !== undefined) {
                this.snackMessageService.readError(error);
              } else {
                this.snackMessageService.sendMessage(error.error);
              }
            }
          });
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initAllCategories() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getCategoryListPublic().subscribe({
        next: (categories: CategoryModel[]) => {
          this.categoriesPublicList = categories;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategoryFeatured() {
    this.arraySubscriptions.push(
      this.categoriesService.getCategoryFeaturedByType('1', 'public').subscribe({
        next: (category) => {
          let _category = category[0];
          this.dataCategoryFeatured = category[0];
          this.dataCategoryFeaturedApplications = [];
          _category.applications.forEach((app, index) => {
            let _appCategoriesData = [];
            app.categories.forEach((category) => {
              let _categoryData = this.categoriesPublicList.find((cat) => cat._id === category);
              if (_categoryData !== undefined) {
                _appCategoriesData.push(_categoryData);
              }
            });
            app.categories = _appCategoriesData;
            this.dataCategoryFeaturedApplications.push(app);
          });
          console.log(this.dataCategoryFeaturedApplications);
          //this.categoryPurchasedId = category._id;
          //this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initDesktop() {
    this.arraySubscriptions.push(
      this.resizeWindowService.isDesktop$.subscribe({
        next: (value: boolean) => {
          this.isDesktop = value;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initWidth() {
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe({
        next: (value: number) => {
          this.width = value;

          this.resizeWindowService.waitForFinalEvent(
            () => {
              this.width = value;
              this.isLoading = false;
            },
            500,
            'loadingHomePublic'
          );
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initStateMenu() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getStateMenu().subscribe({
        next: (state) => {
          this.menuState = state;
          if (this.width === 1024 && this.menuState === 'close') {
            this.showText = true;
          }
          if (this.width === 1024 && this.menuState === 'open') {
            this.showText = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.goTop();
  }

  goTop() {
    //Para que el scroll esté al inicio de la página
    document.getElementsByClassName('main-layout__content')[0].scrollTo(0, 0);
  }

  openModalContact($event) {
    const dialogRef = this.matDialog.open(ModalMessageComponent, {
      height: 'auto',
      width: this.width >= 760 ? '700px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'background-white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        event: $event,
        type: 'contact-form-public',
        imgHeader: 'assets/images/logos/market_avatar.svg',
        title: 'Contact us',
        linkHeader: ' login.',
        textHeader1: 'You need to be a Barbara user to access the Marketplace, please ',
        textHeader2:
          'If you are not a user yet and you are interested in joining the Barbara platform, please fill out the form below and we will get back to you as soon as possible',
        terms: '',
        buttons: [
          {
            matDialogClose: true,
            color: 'purple-inverted',
            icon: 'cancel',
            text: this.labels.close,
            buttonId: 'close-modal',
            action: 'close'
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action !== 'close') {
        this.authService.sendFormContactUs(result);
      }
    });
  }

  ngOnDestroy() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}