import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationModel, CategoryModel } from '../../models/common.model';
import { CategoriesStoreService } from '../../services/category-observable.service';
import { StorageService } from '../../services/storage.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnChanges, OnDestroy {
  @Input() img: string;
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() buttonUrl: string;
  @Input() theme: string = 'marketplace-default';
  @Input() itemsList: ApplicationModel[];
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() context: string;
  @Input() title2: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categorySuggestedId: string;
  @Input() categoryFeatured: CategoryModel;
  @Input() categorySpecial: CategoryModel;
  @Input() categoryPurchased: CategoryModel;
  @Input() categoryMostPurchased: CategoryModel;
  @Input() categorySuggested: CategoryModel;
  @Input() showText: boolean = true;
  @Input() showButtons: boolean = true;
  @Input() showTagsList: boolean = true;
  @Output() closeMenuEmitter: EventEmitter<any> = new EventEmitter();
  className = 'app-empty';
  times: number[] = [];
  arrayApplicationFeatured: any = [];
  arraySubscriptions: Subscription[] = [];

  constructor(
    private categoriesStoreService: CategoriesStoreService,
    private storageService: StorageService,
    private router: Router,
    private utilsSevice: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsList !== undefined && changes.itemsList.firstChange) {
      this.prepareData(this.width);
      this.randomApp();
    }
    if (changes.width !== undefined && changes.width.currentValue !== changes.width.previousValue) {
      this.prepareData(this.width);
      this.randomApp();
    }
    if (
      changes.itemsList !== undefined &&
      changes.itemsList.currentValue !== changes.itemsList.previousValue &&
      !changes.itemsList.firstChange
    ) {
      this.prepareData(this.width);
      this.randomApp();
    }
  }

  randomApp() {
    let numberRandom = [];
    this.arrayApplicationFeatured = [];
    this.utilsSevice.randomNumber(
      this.categorySuggested.applications,
      numberRandom,
      this.times.length
    );
    numberRandom.forEach((idx) => {
      this.arrayApplicationFeatured.push(this.categorySuggested.applications[idx]);
    });
  }

  prepareData(width) {
    this.times = this.utilsSevice.prepareCards(width);
    return this.times;
  }

  closeMenu($event, id): void {
    if (this.storageService.getCategoryId() !== id) {
      this.storageService.setCategoryId(id);
      this.closeMenuEmitter.emit({ action: 'refresh', event: id });
    } else {
      this.closeMenuEmitter.emit({ action: 'refresh', event: id });
    }
  }

  onClickMoreButton(id: string): void {
    this.storageService.setUrl('application-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');
    this.router.navigate(['application-detail', id]);
  }

  ngOnDestroy() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}