<div class="{{ className }}">
  <div class="{{ className }}__category">
    <h3 class="{{ className }}__title">{{ title1 }}</h3>
    <ul class="{{ theme }} {{ className }}__category-list">
      @for (count of times; track $index) {
        @if (count) {
          <li class="{{ theme }} {{ className }}__category-list-item col-{{ times.length }}">
            <skeleton-card
              [type]="'vertical'"
              [width]="width"
              size="col-{{ times.length }}"></skeleton-card>
          </li>
        }
      }
    </ul>
  </div>
  @if (context !== '/private/catalogue' && context !== '/public/') {
    <div class="{{ className }}__category-suggested">
      @if (times.length > 0) {
        <h3 class="{{ className }}__category-suggested-title">{{ title2 }}</h3>
        <ul class="{{ theme }} {{ className }}__category-list">
          @for (count of times; track $index) {
            @if (count) {
              <li class="{{ theme }} {{ className }}__category-list-item col-{{ times.length }}">
                <skeleton-card
                  [type]="'horizontal'"
                  [width]="width"
                  size="col-{{ times.length }}"></skeleton-card>
              </li>
            }
          }
        </ul>
      }
    </div>
  }
</div>