<div class="{{ theme }} {{ className }}__container">
  <!-- BUTTON CLOSE MODAL -->
  <div class="{{ className }}__closeButton">
    @if (data.width < 760) {
      <button (click)="closeModal($event, 'close')" class="small" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    } @else {
      <button (click)="closeModal($event, 'close')" class="big" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
  <div class="{{ theme }} {{ className }}__header">
    <ng-container>
      @if (data.imgHeader !== '' || data.imgHeader !== undefined) {
        <img
          alt="Icon"
          class="{{ theme }} {{ className }}__header-icon"
          src="{{ data.imgHeader }}" />
      }

      <div class="{{ theme }} {{ className }}__header-wrapper">
        <h2 class="{{ theme }} {{ className }}__header-title">{{ data.title }}</h2>
        @if (data.textHeader !== '' && data.textHeader !== undefined) {
          <div
            [innerHtml]="data.textHeader | safe: 'html'"
            class="{{ theme }} {{ className }}__header-text"></div>
        }
        @if (data.textHeader1 !== '' && data.textHeader1 !== undefined) {
          <div
            [innerHtml]="data.textHeader1 | safe: 'html'"
            class="{{ theme }} {{ className }}__header-text"></div>
        }

        @if (data.linkHeader !== '' && data.linkHeader !== undefined) {
          <a (click)="login()" class="{{ theme }} {{ className }}__header-text-link">
            {{ data.linkHeader }}
          </a>
        }
        @if (data.textHeader2 !== '' && data.textHeader2 !== undefined) {
          <div
            [innerHtml]="data.textHeader2 | safe: 'html'"
            class="{{ theme }} {{ className }}__header-text"></div>
        }
      </div>
    </ng-container>
  </div>
  @if (data.type === 'contact-form-public') {
    <!-- HUBSPOT FORM -->
    <app-hubspot (submitedFormEmitter)="onSubmitForm($event)"></app-hubspot>
  } @else {
    <mat-dialog-content class="{{ theme }} {{ className }}__body">
      <!-- MESSAGE FOR CONFIRMATION & ERROR MODALS -->
      <ng-container *ngIf="data.bodyText">
        <div
          [innerHtml]="data.bodyText | safe: 'html'"
          class="{{ theme }} {{ className }}__body-content"></div>
      </ng-container>

      <!-- TERMS & CONDITIONS -->
      <ng-container
        *ngIf="data.terms?.length > 0 && showTerms && data.type !== 'contact-form-public'">
        <div class="{{ theme }} {{ className }}__terms">
          <span class="{{ className }}__terms-toggle">
            {{ labels.termsAndConditions }}
          </span>
          <button mat-icon-button class="small" (click)="colapseTerms = !colapseTerms">
            <mat-icon>{{ colapseTerms ? 'expand_more' : 'expand_less' }}</mat-icon>
          </button>
        </div>
        @if (!colapseTerms) {
          <div
            [innerHtml]="data.terms | safe: 'html'"
            class="{{ theme }} {{ className }}__terms-and-conditions"></div>
        }
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="showFooter" align="end" class="{{ theme }} {{ className }}__footer">
      <ng-container *ngFor="let button of data.buttons; trackBy: identify; let i = index">
        <!-- HUBSPOT FORM SUBMITTED CLOSE BUTTON MODAL -->
        <!--
        <ng-container *ngIf="button.action === 'close' && showButtonClose">
          <div class="{{ theme }} {{ className }}__footer-wrapper">
            <button
              (click)="closeModal($event, button.action)"
              [color]="button.color"
              class="{{ theme }} {{ className }}__button"
              id="{{ button.buttonId }}"
              mat-button>
              <mat-icon class="{{ theme }} {{ className }}__button-icon"
                >{{ button.icon }}
              </mat-icon>
              {{ button.text | uppercase }}
            </button>
          </div>
        </ng-container>
        -->
        <!-- REST BUTTONS MODAL -->
        <ng-container *ngIf="button.action !== 'close'">
          <button
            (click)="emitAction($event, button.action)"
            [color]="button.color"
            class="{{ theme }} {{ className }}__button big"
            id="{{ button.buttonId }}"
            mat-flat-button>
            <mat-icon class="{{ theme }} {{ className }}__button-icon">{{ button.icon }}</mat-icon>
            {{ button.text }}
          </button>
        </ng-container>
      </ng-container>

      <!-- APPLICATIONS TYPE 0 (InstallableApplication) -->
      <ng-container *ngIf="data.originalData?.type === 0 && data.type !== 'confirm'">
        <!-- FREE ADD TO LIBRARY-->
        <ng-container
          *ngIf="data.originalData.price.length === 0 || data.originalData.price[0].value === 0">
          <div class="{{ theme }} {{ className }}__wrapper-price-no-margin">
            <div class="{{ theme }} {{ className }}__price-wrapper">
              <span class="{{ theme }} {{ className }}__price-note">
                {{ labels.upTo100Workloads }}
              </span>
              <span class="{{ theme }} {{ className }}__price-text">{{ labels.free }}</span>
            </div>

            <button
              (click)="emitAction($event, 'addToPanel')"
              class="big"
              color="accent"
              id="add-to-library-2"
              mat-raised-button>
              <mat-icon class="{{ theme }} {{ className }}__button-icon">add</mat-icon>
              {{ labels.addToLibrary }}
            </button>
          </div>
        </ng-container>
        <!-- WITH PRICE START TRIAL-->
        <ng-container
          *ngIf="data.originalData.price.length > 0 && data.originalData.price[0].value > 0">
          <div class="{{ theme }} {{ className }}__wrapper-price-no-margin">
            <div class="{{ theme }} {{ className }}__price-wrapper">
              <span class="{{ theme }} {{ className }}__price-note">{{
                labels.afterTrial | uppercase
              }}</span>
              <span class="{{ theme }} {{ className }}__price-text">
                {{ data.originalData.price[0].value }} €/{{ data.originalData.price[0].unit }}
              </span>
            </div>
            <button
              (click)="emitAction($event, 'startTrial')"
              class="big"
              color="accent"
              id="start-trial-button"
              mat-raised-button>
              {{ labels.startTrial }}
            </button>
          </div>
        </ng-container>
      </ng-container>
      <!-- APPLICATIONS TYPE 1 CONTACT SALES -->
      <ng-container *ngIf="data.originalData?.type === 1 && data.type !== 'confirm'">
        <!-- CONTACT -->
        <ng-container>
          <div class="{{ theme }} {{ className }}__wrapper-price-no-margin">
            <div class="{{ theme }} {{ className }}__price-wrapper">
              <span class="{{ theme }} {{ className }}__price-text">{{ labels.custom }}</span>
            </div>
            <button
              (click)="emitAction($event, 'contact-form-public')"
              class="big"
              color="accent"
              id="contact-button"
              mat-raised-button>
              <mat-icon class="{{ theme }} {{ className }}__button-icon">contact_support</mat-icon>
              {{ labels.contactSales }}
            </button>
          </div>
        </ng-container>
      </ng-container>
    </mat-dialog-actions>
  }
</div>