import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { commonLabels } from '../../common.labels';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() data: any;
  className = 'breadcrumbs';
  theme: string = 'marketplace-default';
  protected readonly labels = commonLabels;

  constructor(private readonly router: Router) {}

  ngOnInit() {
    //console.log(this.data);
  }

  navigateTo(url: string) {
    console.log('navigateTo');

    this.router.navigate([url]);
  }
}