import { Component, OnDestroy, OnInit } from '@angular/core';
import { commonLabels } from '../../common/common.labels';
import { CategoriesStoreService } from '../../common/services/category-observable.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationsService } from '../../common/services/applications.service';
import { ApplicationModel, CategoryModel } from '../../common/models/common.model';
import { CategoriesService } from '../../common/services/categories.service';
import { StorageService } from '../../common/services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalMessageComponent } from '../../common/components/modal-message/modal-message.component';
import { UtilsService } from '../../common/services/utils.service';
import { SnackMessageService } from '../../common/services/snackMessage.service';
import { KeycloakService } from 'keycloak-angular';
import jwt_decode from 'jwt-decode';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { ResizeWindowService } from '../../common/services/resize-window.service';
import { AuthService } from '../../common/services/auth.service';

@Component({
  selector: 'app-view-detail',
  templateUrl: './view-detail.component.html',
  styleUrls: ['./view-detail.component.scss']
})
export class ViewDetailComponent implements OnInit, OnDestroy {
  theme = 'marketplace-default';
  labels = commonLabels;
  categoriesList: CategoryModel[];
  applicationsList: any = [];
  dataApplication: ApplicationModel;
  id: string = '';
  isLoading: boolean = false;
  disabledButton = false;
  showContactButton = false;
  showAddToLibraryButton = false;
  showAlreadyInLibraryButton = false;
  typeModal: string = 'Paid';
  categoryPurchasedId;
  categoryMostPurchasedId;
  categoryFeaturedId;
  categorySuggestedId;
  categorySpecialId;
  categoryFeatured: CategoryModel = undefined;
  categorySuggested: CategoryModel = undefined;
  categorySpecial: CategoryModel = undefined;
  categoryMostPurchased: CategoryModel = undefined;
  categoryPurchased: CategoryModel = undefined;
  arrayApplicationFeatured: any = [];
  arraySubscriptions: Subscription[] = [];
  userProfile: any;
  width: number = 0;

  constructor(
    public matDialog: MatDialog,
    private resizeWindowService: ResizeWindowService,
    private categoriesService: CategoriesService,
    private applicationsService: ApplicationsService,
    private categoriesStoreService: CategoriesStoreService,
    private storageService: StorageService,
    private snackMessageService: SnackMessageService,
    private utilsService: UtilsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnInit(): void {
    //console.log(this.id);
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.isLoading = true;
    const url = this.router.url;
    //console.log(url);
    this.id = url.split('/').pop();
    console.log(this.id);
    this.initCategoryList();
    this.initPurchasedCategory();
    this.initMostPurchasedCategory();
    this.initSpecialCategory();
    this.initFeaturedCategory();
    this.initSuggestedCategory();
    this.initApplicationById();
    this.initWidth();
  }

  initWidth() {
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe({
        next: (value: number) => {
          this.width = value;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategoryList() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getCategoryList().subscribe({
        next: (data) => {
          this.categoriesList = data;
          console.log(this.categoriesList);
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initPurchasedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getPurchasedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categoryPurchased = category;
          this.categoryPurchasedId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initMostPurchasedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getMostPurchasedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categoryMostPurchased = category;
          this.categoryMostPurchasedId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initSpecialCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSpecialCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categorySpecial = category;
          this.categorySpecialId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initFeaturedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getFeaturedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categoryFeatured = category;
          this.categoryFeaturedId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initSuggestedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSuggestedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categorySuggested = category;
          this.categorySuggestedId = category._id;
          if (
            this.categorySuggested.applications !== undefined &&
            this.categorySuggested.applications.length > 5
          ) {
            this.randomApp();
          } else {
            this.arrayApplicationFeatured = this.categorySuggested.applications;
          }
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initApplicationById() {
    this.applicationsService.getApplicationById(this.id).subscribe({
      next: (data) => {
        this.dataApplication = data;
        this.configButtons(this.dataApplication);
        this.isLoading = false;
        window.scrollBy(0, -window.innerHeight);
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
        if (this.authService.isLoggedIn()) {
          this.router.navigate(['home']);
        } else {
          this.router.navigate(['/public/_home']);
        }
      }
    });
  }

  configButtons(dataApp): void {
    switch (dataApp.type) {
      case 0:
        if (dataApp.versions.length > 0) {
          this.disabledButton = false;
        } else {
          this.disabledButton = true;
        }
        this.showContactButton = false;
        this.showAddToLibraryButton = true;
        this.showAlreadyInLibraryButton = false;
        this.typeModal = 'Version';
        break;
      case 1:
        this.disabledButton = false;
        this.showContactButton = false;
        this.showAddToLibraryButton = true;
        this.showAlreadyInLibraryButton = false;
        this.typeModal = 'Terms';
        break;
      default:
        this.disabledButton = false;
        this.showContactButton = true;
        this.showAddToLibraryButton = false;
        this.showAlreadyInLibraryButton = false;
        this.typeModal = 'contact-form-public';
        break;
    }
    if (dataApp.categories.find((category) => category._id === this.categoryPurchasedId)) {
      this.disabledButton = true;
      this.showContactButton = false;
      this.showAddToLibraryButton = false;
      this.showAlreadyInLibraryButton = true;
    }
  }

  randomApp() {
    let numberRandom = [];
    this.arrayApplicationFeatured = [];
    this.utilsService.randomNumber(this.categorySuggested.applications, numberRandom);
    numberRandom.forEach((idx) => {
      this.arrayApplicationFeatured.push(this.categorySuggested.applications[idx]);
    });
  }

  onClickMoreButton($event): void {
    this.storageService.setUrl('application-detail/' + $event.id);
    this.router.navigate(['application-detail', $event.id]);
    setTimeout(() => {
      this.initSubscriptions();
    }, 500);
  }

  addToLibrary($event): void {
    const dialogRef = this.matDialog.open(ModalMessageComponent, {
      height: 'auto',
      width: this.width >= 760 ? '700px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'background-white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        event: $event,
        width: this.width,
        type: this.typeModal,
        originalData: this.dataApplication,
        imgHeader: this.dataApplication.icon,
        title: this.dataApplication.name,
        textHeader: this.dataApplication.shortDescription,
        terms: this.utilsService.conversorString(this.dataApplication.termsAndConditions),
        showFooter: true,
        showButtonClose: true,
        buttons: [
          {
            matDialogClose: true,
            color: 'purple-inverted',
            icon: 'cancel',
            text: this.labels.close,
            buttonId: 'close-modal',
            action: 'close'
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result.action !== 'close') {
        switch (result.action) {
          case 'contact-form-public':
            this.contact(result);
            break;
          case 'startTrial':
            this.createDeal(result.element);
            this.applicationsService.addToLibrary(result.element._id).subscribe({
              next: (data) => {
                this.prepareDataCategories();
                this.initSubscriptions();
              },
              error: (error) => {
                this.isLoading = false;
                if (error.error.response !== undefined) {
                  this.snackMessageService.readError(error);
                } else {
                  this.snackMessageService.sendMessage(error.error);
                }
              }
            });
            break;
          case 'addToPanel':
            this.applicationsService.addToLibrary(result.element._id).subscribe({
              next: (data) => {
                this.prepareDataCategories();
                this.initSubscriptions();
              },
              error: (error) => {
                this.isLoading = false;
                if (error.error.response !== undefined) {
                  this.snackMessageService.readError(error);
                } else {
                  this.snackMessageService.sendMessage(error.error);
                }
              }
            });
            break;
        }
      }
    });
  }

  prepareDataCategories() {
    this.categoriesService.getAllCategories().subscribe({
      next: (categories: any) => {
        this.categoriesList = [];
        this.categoriesList = categories;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
    this.applicationsService.getApplicationsSearch('', 0, 100).subscribe({
      next: (data) => {
        this.applicationsList = [];
        this.applicationsList = data['applicationList'];
        this.utilsService.addTagsList(this.applicationsList);
        this.updateCategoriesList();
      },
      error: (error) => {
        console.error(error);
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  updateCategoriesList() {
    this.isLoading = true;
    if (
      this.categoriesList !== undefined &&
      this.categoriesList.length > 0 &&
      this.applicationsList !== undefined &&
      this.applicationsList.length > 0
    ) {
      let _dataCategory = cloneDeep(this.categoriesList);
      let _category = [];
      _dataCategory.forEach((category, index) => {
        let _applications = [];
        _category.push(category);
        _category[index].applications.forEach((application) => {
          //Actualizamos las apps de categorias (vienen sin precio) con las del listado de apps
          let _app = this.applicationsList.filter((app) => {
            if (app._id === application._id) {
              return app._id === application._id;
            }
          });
          if (_app.length > 0) {
            _applications.push(_app[0]);
          }
        });
        _category[index].applications = _applications;
      });
      this.categoriesList = _dataCategory;
      this.categoriesStoreService.setCategoryList(this.categoriesList);
      this.isLoading = false;
    }
  }

  async createDeal(dataApp) {
    this.userProfile = await this.keycloak.loadUserProfile();
    const tokenKeycloak = await this.keycloak.getToken();
    const dataCompany = jwt_decode(tokenKeycloak)['companyName'];
    const json = {
      properties: [
        {
          value: `Marketplace App Purchase - ${dataApp.name} - ${dataCompany}`,
          name: 'dealname'
        },
        {
          value: 'default',
          name: 'pipeline'
        },
        { value: 'appointmentscheduled', name: 'dealstage' },
        {
          value: 'MARKETPLACE',
          name: 'origen_del_negocio'
        },
        {
          value: `The user ${this.userProfile.firstName} ${this.userProfile.lastName} has downloaded the ${dataApp.name} app (app ID = ${dataApp._id}) from Barbara Marketplace. Before the trial is finished, the user must be contacted to confirm the purchase. His/her contact details are: ${this.userProfile.email} and ${this.userProfile.attributes.phone[0]}`,
          name: 'description'
        }
      ]
    };
    this.applicationsService.createDeal(json).subscribe({
      next: (data) => {
        console.log(data);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  createDealnoBarbaraApp(dataApp, dataUser) {
    let json;
    if (dataApp.type === 1) {
      json = {
        properties: [
          {
            value: `Marketplace App Purchase Request - ${dataApp.name} - ${dataUser.company}`,
            name: 'dealname'
          },
          {
            value: 'default',
            name: 'pipeline'
          },
          { value: 'appointmentscheduled', name: 'dealstage' },
          {
            value: 'MARKETPLACE',
            name: 'origen_del_negocio'
          },
          {
            value: `The user ${dataUser.firstname} ${dataUser.secondname} has requested the purchase of ${dataApp.name} app (app ID = ${dataApp._id}) in Barbara Marketplace. The user must be contacted ASAP. His/her contact details are: ${dataUser.email} and ${dataUser.phone}`,
            name: 'description'
          }
        ]
      };
    } else {
      json = {
        properties: [
          {
            value: `Marketplace Service Info Request - ${dataApp.name} - ${dataUser.company}`,
            name: 'dealname'
          },
          {
            value: 'default',
            name: 'pipeline'
          },
          { value: 'appointmentscheduled', name: 'dealstage' },
          {
            value: 'MARKETPLACE',
            name: 'origen_del_negocio'
          },
          {
            value: `The user ${dataUser.firstname} ${dataUser.secondname} has requested information about the services provided by ${dataApp.name} app (app ID = ${dataApp._id}) through Barbara Marketplace. The user must be contacted ASAP. His/her contact details are: ${dataUser.email} and ${dataUser.phone}`,
            name: 'description'
          }
        ]
      };
    }

    this.applicationsService.createDeal(json).subscribe({
      next: (data) => {
        console.log(data);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  contact($event): void {
    const dialogRef = this.matDialog.open(ModalMessageComponent, {
      height: 'auto',
      width: this.width >= 760 ? '700px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'background-white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        event: $event,
        width: this.width,
        type: 'contact-form-public',
        imgHeader: 'assets/images/logos/market_avatar.svg',
        title: 'Contact us',
        textHeader:
          'Please fill out the form below and we will get back to you as soon as possible.',
        terms: '',
        buttons: [
          {
            matDialogClose: true,
            color: 'accent',
            icon: 'cancel',
            text: this.labels.close,
            buttonId: 'close-modal',
            action: 'close'
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      if (result.action !== 'close') {
        this.createDealnoBarbaraApp(this.dataApplication, result);
      }
    });
  }

  removeSubscriptions() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }
}