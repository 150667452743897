<div class="{{ theme }} {{ className }}" id="home-private">
  @if (isLoading) {
    <!-- Top Selling App -->
    <ng-container>
      <div class="{{ theme }} {{ className }}__top-selling-wrapper">
        <h3 class="{{ theme }} {{ className }}__top-selling-title">
          <mat-icon
            class="material-icons-outlined {{ theme }} {{ className }}__top-selling-title-icon">
            {{ categoryMostPurchased.icon }}
          </mat-icon>
          <span class="{{ className }}__top-selling-title-text">
            {{ categoryMostPurchased.name }}
          </span>
        </h3>
        <ul class="{{ className }}__top-selling-list">
          @for (skeleton of skeletonTopSelling; track $index) {
            <li class="{{ className }}__top-selling-list-item">
              <div
                class="{{ theme }} {{ className }}__top-selling-list-item {{
                  className
                }}__wrapper vertical">
                <div class="{{ className }}__top-selling-list-item skeleton vertical-img"></div>
              </div>
            </li>
          }
        </ul>
      </div>
    </ng-container>

    <!-- Featured -->
    <ng-container>
      <div class="{{ theme }} {{ className }}__featured-wrapper">
        <div class="{{ theme }} {{ className }}__category-wrapper featured">
          <h3 class="{{ theme }} {{ className }}__featured-title">
            {{ categoryFeatured.name }}
          </h3>
          <div class="{{ theme }} {{ className }}__category-buttons"></div>
        </div>
        <div class="{{ theme }} {{ className }}__featured-list-wrapper">
          <ul class="{{ theme }} {{ className }}__featured-list">
            @for (skeleton of skeletonFeatured; track $index) {
              <li
                *ngIf="
                  width >= 3800
                    ? $index <= 4
                    : width < 3800 && width >= 1800
                      ? $index <= 3
                      : $index <= 2
                "
                class="{{ theme }} {{ className }}__featured-list-item">
                <skeleton-card [type]="'vertical'" [width]="width" [size]="'big'"></skeleton-card>
              </li>
            }
          </ul>
        </div>
      </div>
    </ng-container>

    <!-- Categories type 0 Standard-->
    <ng-container>
      <div class="{{ theme }} {{ className }}__categories-wrapper">
        <h3 class="{{ theme }} {{ className }}__categories-title">
          {{ labels.categories }}
        </h3>
        <ul class="{{ theme }} {{ className }}__category-list">
          @for (skeleton of skeletonCategories; track $index) {
            <li class="{{ theme }} {{ className }}__category-list-item">
              <skeleton-card [type]="'vertical'" [width]="width" [size]="'medium'"></skeleton-card>
            </li>
          }
        </ul>
      </div>
    </ng-container>
  }

  @if (!isLoading) {
    <!-- Top Selling App -->
    @if (categoryMostPurchased !== undefined && categoryMostPurchased.applications.length > 0) {
      <ng-container>
        <div class="{{ theme }} {{ className }}__top-selling-wrapper">
          <h3 class="{{ theme }} {{ className }}__top-selling-title">
            <mat-icon
              class="material-icons-outlined {{ theme }} {{ className }}__top-selling-title-icon">
              {{ categoryMostPurchased.icon }}
            </mat-icon>
            <span class="{{ className }}__top-selling-title-text">
              {{ categoryMostPurchased.name }}
            </span>
          </h3>
          <ul class="{{ className }}__top-selling-list">
            @for (application of categoryMostPurchased.applications; track $index) {
              <li
                (click)="onClickMoreButton(application._id)"
                class="{{ className }}__top-selling-list-item">
                <img
                  matTooltip=" {{ application.name }}"
                  matTooltipClass="{{ theme }} custom-tooltip"
                  matTooltipPosition="above"
                  src="{{ application.pictures[0] }}"
                  alt="app image"
                  class="{{ theme }} {{ className }}__top-selling-list-item-image" />
              </li>
            }
          </ul>
        </div>
      </ng-container>
    }

    <!-- Featured -->
    @if (categoryFeatured !== undefined && categoryFeatured.applications?.length > 0) {
      <ng-container>
        <div class="{{ theme }} {{ className }}__featured-wrapper">
          <div class="{{ theme }} {{ className }}__category-wrapper featured">
            <h3 class="{{ theme }} {{ className }}__featured-title">
              {{ categoryFeatured.name }}
            </h3>
            <div class="{{ theme }} {{ className }}__category-buttons"></div>
          </div>
          <div class="{{ theme }} {{ className }}__featured-list-wrapper">
            <ul class="{{ theme }} {{ className }}__featured-list">
              @for (app of arrayApplicationFeatured; track $index) {
                <ng-container *ngIf="app.enabled && !app.isPublic">
                  <li
                    *ngIf="
                      width >= 3800
                        ? $index <= 4
                        : width < 3800 && width >= 1800
                          ? $index <= 3
                          : $index <= 2
                    "
                    class="{{ theme }} {{ className }}__featured-list-item">
                    <app-card-vertical
                      (moreButtonEvent)="onClickMoreButton(app._id)"
                      [_data]="app"
                      [dataCategory]="dataCategory"
                      [categoryFeaturedId]="categoryFeaturedId"
                      [categoryFeatured]="categoryFeatured"
                      [categoryMostPurchasedId]="categoryMostPurchasedId"
                      [categoryMostPurchased]="categoryMostPurchased"
                      [categoryPurchasedId]="categoryPurchasedId"
                      [categoryPurchased]="categoryPurchased"
                      [categorySpecialId]="categorySpecialId"
                      [categorySpecial]="categorySpecial"
                      [context]="'/private/home'"
                      [isDesktop]="isDesktop"
                      [showButtons]="false"
                      [showTagsList]="false"
                      [showText]="true"
                      [theme]="theme"
                      [width]="width"></app-card-vertical>
                  </li>
                </ng-container>
              }
            </ul>
          </div>
        </div>
      </ng-container>
    }

    <!-- Categories type 0 Standard-->
    <ng-container *ngIf="dataCategory.length > 0">
      <div class="{{ theme }} {{ className }}__categories-wrapper">
        <h3 class="{{ theme }} {{ className }}__categories-title">
          {{ labels.categories }}
        </h3>
        @for (category of dataCategory; track $index) {
          <div class="{{ theme }} {{ className }}__category">
            <ng-container *ngIf="category.applications?.length > 0 && category.type === 0">
              <div class="{{ theme }} {{ className }}__category-wrapper">
                <h3
                  (click)="clickCategorySeeAll(category._id)"
                  class="{{ theme }} {{ className }}__category-title"
                  id="title-category-{{ utilsService.removeSpacesAndInsertScript(category.name) }}">
                  <span class="{{ theme }} {{ className }}__category-title-text">
                    {{ category.name }}
                  </span>
                </h3>
                <div class="{{ theme }} {{ className }}__category-buttons">
                  @if (isDesktop) {
                    <span class="{{ theme }} {{ className }}__category-buttons-text">
                      {{ labels.seeAll }}
                    </span>
                  }
                  <button
                    (click)="clickCategorySeeAll(category._id)"
                    class="big"
                    color="primary"
                    id="button-mobile-category-{{
                      utilsService.removeSpacesAndInsertScript(category.name)
                    }}"
                    mat-icon-button
                    matTooltip="{{ labels.seeAll }}"
                    matTooltipClass="{{ theme }} custom-tooltip"
                    matTooltipPosition="above">
                    <mat-icon class="material-icons-outline">chevron_right</mat-icon>
                  </button>
                </div>
              </div>

              <ul
                *ngIf="category.applications.length > 0"
                class="{{ theme }} {{ className }}__category-list">
                <ng-container
                  *ngFor="let app of category.applications; trackBy: identify; let i = index">
                  <ng-container *ngIf="app.enabled && !app.isPublic">
                    <li class="{{ theme }} {{ className }}__category-list-item">
                      <app-card-vertical
                        (moreButtonEvent)="onClickMoreButton(app._id)"
                        [_data]="app"
                        [categoryFeaturedId]="categoryFeaturedId"
                        [categoryFeatured]="categoryFeatured"
                        [categoryMostPurchasedId]="categoryMostPurchasedId"
                        [categoryMostPurchased]="categoryMostPurchased"
                        [categoryPurchasedId]="categoryPurchasedId"
                        [categoryPurchased]="categoryPurchased"
                        [categorySpecialId]="categorySpecialId"
                        [categorySpecial]="categorySpecial"
                        [context]="'/private/home'"
                        [dataCategory]="dataCategory"
                        [isDesktop]="isDesktop"
                        [showButtons]="true"
                        [showTagsList]="true"
                        [showText]="true"
                        [theme]="theme"
                        [width]="width"></app-card-vertical>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        }
      </div>
    </ng-container>
  }
</div>