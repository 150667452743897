import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';

import { ResizeWindowService } from './common/services/resize-window.service';

import { AppComponent } from './app.component';

import { ScrollDispatcher, ScrollingModule } from '@angular/cdk/scrolling';
import { environment } from '../environments/environment';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MY_MAT_TOOLTIP_DEFAULT_OPTIONS, SharedCommonModule } from './common/shared-common.module';
import { FormsCommonModule } from './common/forms-common.module';
import { TablesCommonModule } from './common/tables-common.module';
import { ApplicationModule } from './main/application/application.module';
import { CategoryModule } from './main/category/category.module';
import { ResultModule } from './main/result/result.module';
import { CategoriesService } from './common/services/categories.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

function initializeKeycloak(keycloak: KeycloakService) {
  //console.log(environment.auth_server);
  return () => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: environment.auth_server,
            realm: environment.keycloak_realm,
            clientId: 'barbara_market_frontend'
          },
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
            pkceMethod: 'S256',

            checkLoginIframeInterval: 1
          },
          enableBearerInterceptor: true
        });

        const keycloakAuth = keycloak.getKeycloakInstance();

        const updateToken = async (): Promise<string> => {
          try {
            await keycloakAuth.updateToken(5);
            //console.log('updateToken', keycloakAuth.token);
            console.log('updateToken');
            return keycloakAuth.token;
          } catch (error) {
            throw error;
          }
        };
        const login = async (): Promise<void> => {
          try {
            await keycloakAuth.login();
            console.log('login successful');
          } catch (error) {
            this.router.navigate(['/public/_home']);
            //throw error;
          }
        };

        keycloakAuth.onTokenExpired = () => {
          console.log('expired ' + new Date());
          updateToken();
        };

        resolve('Keycloak initialized');
      } catch (error) {
        reject(error);
      }
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    SharedCommonModule,
    FormsCommonModule,
    TablesCommonModule,
    LayoutModule,
    ApplicationModule,
    CategoryModule,
    ResultModule,
    ScrollingModule,
    AppRoutingModule
  ],
  providers: [
    ResizeWindowService,
    CategoriesService,
    ScrollDispatcher,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: MY_MAT_TOOLTIP_DEFAULT_OPTIONS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}