<div class="{{ className }}__breadcrumb">
  <ul class="{{ className }}__breadcrumb-list">
    <li class="{{ className }}__breadcrumb-list-item">
      <a
        (click)="navigateTo('/home')"
        [routerLink]="['home']"
        class="{{ className }}__breadcrumb-list-item-link">
        <button
          class="small"
          color="primary"
          disableRipple="true"
          id="button-back-to"
          mat-icon-button>
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span class="{{ className }}__breadcrumb-list-item-link-text">Back to</span>
      </a>
    </li>
    <li class="{{ className }}__breadcrumb-list-item">
      <a
        (click)="navigateTo('/home')"
        [routerLink]="['home']"
        class="{{ className }}__breadcrumb-list-item-link">
        <mat-icon class="{{ className }}__breadcrumb-list-item-link-icon">home</mat-icon>
        <span class="{{ className }}__breadcrumb-list-item-link-text">Home</span>
      </a>
    </li>
  </ul>
</div>