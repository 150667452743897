<div class="{{ className }}">
  <div class="{{ className }}__wrapper">
    @if (
      isLoading ||
      itemsList === undefined ||
      categorySuggested === undefined ||
      categoryFeatured === undefined
    ) {
      <skeleton-cards-list
        [title1]="categoryName"
        [title2]="'Suggested Apps'"
        [context]="context"></skeleton-cards-list>
    }
    @if (
      !isLoading &&
      itemsList !== undefined &&
      categorySuggested !== undefined &&
      categoryFeatured !== undefined
    ) {
      <div class="{{ className }}__results-apps">
        <h3 class="{{ className }}__title">{{ categoryName }}</h3>
        @if (itemsList.length > 0) {
          <cards-list
            (refreshDataEmitter)="refreshData()"
            [itemsList]="itemsList"
            [theme]="theme"
            [isDesktop]="isDesktop"
            [width]="width"
            [categoryFeaturedId]="categoryFeaturedId"
            [categorySuggestedId]="categorySuggestedId"
            [categorySpecialId]="categorySpecialId"
            [categoryPurchasedId]="categoryPurchasedId"
            [categoryMostPurchasedId]="categoryMostPurchasedId"
            [categoryFeatured]="categoryFeatured"
            [categorySuggested]="categorySuggested"
            [categorySpecial]="categorySpecial"
            [categoryPurchased]="categoryPurchased"
            [categoryMostPurchased]="categoryMostPurchased"
            [context]="context"
            [title2]="categorySuggested['name'] ?? 'Suggested Apps'"
            [showButtons]="true"
            [showTagsList]="true"
            [showText]="showText"></cards-list>
        } @else {
          <app-empty
            (closeMenuEmitter)="closeMenu($event)"
            [img]="img"
            [title]="title"
            [description]="description"
            [buttonText]="buttonText"
            [buttonUrl]="buttonUrl"
            [theme]="theme"
            [isDesktop]="isDesktop"
            [width]="width"
            [categoryFeaturedId]="categoryFeaturedId"
            [categorySuggestedId]="categorySuggestedId"
            [categorySpecialId]="categorySpecialId"
            [categoryPurchasedId]="categoryPurchasedId"
            [categoryMostPurchasedId]="categoryMostPurchasedId"
            [categoryFeatured]="categoryFeatured"
            [categorySuggested]="categorySuggested"
            [categorySpecial]="categorySpecial"
            [categoryPurchased]="categoryPurchased"
            [categoryMostPurchased]="categoryMostPurchased"
            [context]="context"
            [title2]="categorySuggested['name'] ?? 'Suggested Apps'"
            [showButtons]="true"
            [showTagsList]="true"
            [showText]="showText"></app-empty>
        }
      </div>
    }
  </div>
</div>

<!-- Viejo
<div *ngIf="isLoading" class="{{ theme }} spinner">
  <mat-spinner class="{{ theme }}" color="purple" diameter="150" strokeWidth="10"></mat-spinner>
</div>

<div *ngIf="dataCategory && !isLoading" class="{{ theme }} {{ className }}">
  <div class="{{ theme }} {{ className }}__category">
    <div class="{{ theme }} {{ className }}__category-wrapper">
      <h3
        class="{{ theme }} {{ className }}__category-title"
        id="title-{{ utilsService.removeSpacesAndInsertScript(dataCategory.name) }}">
        <span class="{{ theme }} {{ className }}__category-title-text">{{
          dataCategory.name
        }}</span>
      </h3>
    </div>
    <ng-container
      *ngIf="
        dataCategory.applications.length > 0 &&
        dataCategory.type !== 1 &&
        dataCategory.type !== 3 &&
        dataCategory.type !== 4
      ">
      <ul class="{{ theme }} {{ className }}__category-list">
        <ng-container *ngFor="let app of dataCategory.applications; trackBy: identify">
          <ng-container *ngIf="app.enabled && !app.isPublic">
            <li class="{{ theme }} {{ className }}__category-list-item">
              <app-card-horizontal
                (moreButtonEvent)="onClickMoreButton(app._id)"
                [_data]="app"
                [categoryFeaturedId]="categoryFeaturedId"
                [categoryFeatured]="categoryFeatured"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryMostPurchased]="categoryMostPurchased"
                [categoryPurchasedId]="categoryPurchasedId"
                [categoryPurchased]="categoryPurchased"
                [categorySpecialId]="categorySpecialId"
                [categorySpecial]="categorySpecial"
                [isDesktop]="isDesktop"
                [theme]="theme"
                [width]="width"></app-card-horizontal>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
    <ng-container
      *ngIf="
        (dataCategory.type === 1 || dataCategory.type === 3 || dataCategory.type === 4) &&
        dataCategory.applications.length > 0
      ">
      <ul class="{{ theme }} {{ className }}__category-list">
        <ng-container *ngFor="let app of dataCategory.applications; trackBy: identify">
          <ng-container *ngIf="app.enabled && !app.isPublic">
            <li class="{{ theme }} {{ className }}__category-list-item">
              <app-card-vertical
                (moreButtonEvent)="onClickMoreButton(app._id)"
                [_data]="app"
                [categoryFeaturedId]="categoryFeaturedId"
                [categoryFeatured]="categoryFeatured"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryMostPurchased]="categoryMostPurchased"
                [categoryPurchasedId]="categoryPurchasedId"
                [categoryPurchased]="categoryPurchased"
                [categorySpecialId]="categorySpecialId"
                [categorySpecial]="categorySpecial"
                [context]="context"
                [dataCategory]="dataCategory"
                [isDesktop]="isDesktop"
                [showText]="showText"
                [theme]="theme"
                [width]="width"></app-card-vertical>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
    <p
      *ngIf="dataCategory.applications.length === 0"
      class="{{ theme }} {{ className }}__category-list-no-data">
      {{ labels.noAppsFound }}
    </p>
  </div>
  -->
<!--Featured
<ng-container
  *ngIf="
    dataCategory._id !== categoryFeaturedId &&
    dataCategory._id !== categoryMostPurchasedId &&
    dataCategory._id !== categorySpecialId &&
    categoryFeatured.applications?.length > 0
  ">
  <div class="{{ theme }} {{ className }}__category-featured">
    <div class="{{ theme }} {{ className }}__category-wrapper">
      <h3 class="{{ theme }} {{ className }}__category-title">
        <span class="{{ theme }} {{ className }}__category-title-text">{{
          categoryFeatured.name
        }}</span>
      </h3>
    </div>
    <p
      *ngIf="categoryFeatured.applications.length === 0"
      class="{{ theme }} {{ className }}__category-list-no-data">
      {{ labels.noAppsFound }}
    </p>
    <ul
      *ngIf="categoryFeatured.applications.length > 0"
      class="{{ theme }} {{ className }}__category-list">
      <ng-container
        *ngFor="let app of arrayApplicationFeatured; trackBy: identify; let i = index">
        <ng-container *ngIf="app.enabled">
          <li
            *ngIf="width >= 3800 ? i <= 4 : width < 3800 && width >= 1800 ? i <= 3 : i <= 2"
            class="{{ theme }} {{ className }}__category-list-item">
            <app-card-vertical
              (moreButtonEvent)="onClickMoreButton(app._id)"
              *ngIf="app.enabled"
              [_data]="app"
              [categoryFeaturedId]="categoryFeaturedId"
              [categoryFeatured]="categoryFeatured"
              [categoryMostPurchasedId]="categoryMostPurchasedId"
              [categoryMostPurchased]="categoryMostPurchased"
              [categoryPurchasedId]="categoryPurchasedId"
              [categoryPurchased]="categoryPurchased"
              [categorySpecialId]="categorySpecialId"
              [categorySpecial]="categorySpecial"
              [context]="context"
              [dataCategory]="dataCategory"
              [isDesktop]="isDesktop"
              [showText]="true"
              [theme]="theme"
              [width]="width"></app-card-vertical>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</ng-container>
</div>
-->