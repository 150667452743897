import { Component, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from './common/services/storage.service';
import { AuthService } from './common/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ResizeWindowService } from './common/services/resize-window.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'market-place';
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  isDesktop = false;
  theme = 'marketplace-default';
  isLoading = true;
  userLogIn = true;
  isAdmin = false;
  isCompanyAdmin = false;
  userName = '';
  userAvatar = '';
  userProfile;

  constructor(
    private readonly resizeWindowService: ResizeWindowService,
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly keycloak: KeycloakService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.userLogIn = await this.keycloak.isLoggedIn();
    //console.log('userLogIn', this.userLogIn);
    await this.keycloak.updateToken(5);
    this.initSubscriptions();
    if (this.userLogIn) {
      this.authService.setUserLoggedIn(true);
      //console.log(await this.keycloak.getUserRoles());
      this.isCompanyAdmin = this.keycloak.isUserInRole('role_marketplace_company_admin');
      this.isAdmin = this.keycloak.isUserInRole('role_marketplace_superadmin');
      //console.log('isAdmin', this.isAdmin);
      localStorage.setItem('isAdmin', this.isAdmin.toString());

      const token = this.keycloak.getToken();
      this.userProfile = await this.keycloak.loadUserProfile();
      this.userName = this.userProfile.firstName + ' ' + this.userProfile.lastName;
      this.userAvatar = this.userProfile.attributes.avatar[0];
      if (this.isAdmin) {
        this.authService.setIsAdmin(true);
        if (window.location.pathname.startsWith('/admin')) {
          this.router.navigate([window.location.pathname]);
        } else {
          this.router.navigate(['home']);
        }
      } else {
        this.authService.setIsAdmin(false);
        //console.log(window.location.pathname);
        if (window.location.pathname.startsWith('/public')) {
          this.router.navigate(['home']);
        } else {
          this.router.navigate([window.location.pathname]);
        }
      }
      this.isLoading = false;
    } else {
      this.authService.setUserLoggedIn(false);

      //console.log(window.location.pathname);
      if (
        window.location.pathname === '/home' ||
        window.location.pathname === '/results' ||
        window.location.pathname === '/catalogue'
      ) {
        this.storageService.removeStoreSearch();
        this.router.navigate(['/public/_home']);
      } else {
        this.router.navigate([window.location.pathname]);
      }
      this.isLoading = false;
    }
  }

  initSubscriptions(): void {
    this.resizeWindowService.isDesktop$.subscribe((value: boolean) => {
      this.isDesktop = value;
    });
    this.resizeWindowService.width$.subscribe((value: number) => {
      this.width = value;
    });
  }

  navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  public retrieveAuthToken = (): string => {
    return sessionStorage.getItem('authToken');
  };

  ngOnDestroy(): void {}
}