import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationModel, CategoryModel, CommonLabels } from 'app/common/models/common.model';

import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { CategoriesStoreService } from '../../../common/services/category-observable.service';
import { MatTabGroup } from '@angular/material/tabs';
import { StorageService } from '../../../common/services/storage.service';
import { UtilsService } from '../../../common/services/utils.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.scss']
})
export class ApplicationDetailComponent implements OnInit, OnDestroy {
  theme = 'marketplace-default';
  className = 'app-application-detail';
  isDesktop: boolean = false;
  width: number = 0;
  @Input() categoriesList: CategoryModel[];
  @Input() labels: CommonLabels;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId?: string;
  @Input() categoryFeaturedId?: string;
  @Input() categorySuggestedId?: string;
  @Input() categorySpecialId?: string;
  @Input() categoryFeatured?: CategoryModel;
  @Input() categorySuggested?: CategoryModel;
  @Input() categorySpecial?: CategoryModel;
  @Input() categoryPurchased?: CategoryModel;
  @Input() categoryMostPurchased?: CategoryModel;
  @Input() arrayApplicationFeatured?: any = [];
  @Input() dataApplication: ApplicationModel;
  @Input() disabledButton: boolean;
  @Input() showContactButton: boolean;
  @Input() showAddToLibraryButton: boolean;
  @Input() showAlreadyInLibraryButton: boolean;
  @Input() typeModal: string;

  @Output() emitAddToLibrary: EventEmitter<any> = new EventEmitter();
  @Output() emitContact: EventEmitter<any> = new EventEmitter();
  @Output() emitClickMore: EventEmitter<any> = new EventEmitter();
  @ViewChild('tabList', { static: false }) tabList: MatTabGroup;

  isLoading: boolean = false;
  tabSelectedIndex: number = 0;
  lastVersion: number = 0;
  readme: string = '';
  showBarbara: boolean = false;
  showMostUsed: boolean = false;
  showFeatured: boolean = false;
  menuState: string = '';
  title: string;
  shortDescription: string;
  arraySubscriptions: Subscription[] = [];

  constructor(
    private resizeWindowService: ResizeWindowService,
    private readonly router: Router,
    private storageService: StorageService,
    private categoriesStoreService: CategoriesStoreService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.initSubscriptions();
    console.log(this.categoriesList);
  }

  initSubscriptions() {
    this.arraySubscriptions.push(
      this.resizeWindowService.isDesktop$.subscribe((value: boolean) => {
        this.isDesktop = value;
      })
    );
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe((value: number) => {
        this.width = value;

        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.width = value;
            if (
              this.dataApplication.versions !== undefined &&
              this.dataApplication.versions.length > 0
            ) {
              this.lastVersion = this.dataApplication.versions.length - 1;
              if (
                this.dataApplication.versions[this.lastVersion].readme !== '' &&
                this.dataApplication.versions[this.lastVersion].readme !== null &&
                this.dataApplication.versions[this.lastVersion].readme !== undefined
              ) {
                this.readme = this.utilsService.conversorString(
                  this.dataApplication.versions[this.lastVersion].readme
                );
              }
            }
            this.dataApplication.categories.forEach((category) => {
              let _temp = this.categoriesList.find((cat) => cat._id === category._id);
              category.description = _temp.description;
              category.icon = _temp.icon;
              category.order = _temp.order;
              category.type = _temp.type;
              if (category.type === 1) {
                this.showFeatured = true;
              }
              if (category.type === 2) {
                this.setPurchasedApplication();
              }
              if (category.type === 3) {
                this.showMostUsed = true;
              }
              if (category.type === 4) {
                this.showBarbara = true;
              }
            });
            this.categoriesStoreService.getStateMenu().subscribe((state) => {
              this.menuState = state;
              this.title = this.dataApplication.name.replace(/<[^>]*>/g, '');
              this.shortDescription = this.dataApplication.shortDescription.replace(/<[^>]*>/g, '');
              this.isLoading = false;
            });
          },
          10,
          'loadingAppDetail'
        );
      })
    );
  }

  setPurchasedApplication() {
    this.dataApplication.purchased = true;
  }

  addToLibrary(id: string, typeModal): void {
    this.emitAddToLibrary.emit({ id: id, typeModal: typeModal, width: this.width });
  }

  contact(id: string, typeModal): void {
    this.emitContact.emit({ id: id, typeModal: typeModal, width: this.width });
  }

  onClickMoreButton(id: string): void {
    this.emitClickMore.emit({ id: id, width: this.width });
  }

  selectedTabChange(event) {
    this.tabSelectedIndex = event.index;
  }

  identify(index, item) {
    return index;
  }

  removeSubscriptions() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }
}