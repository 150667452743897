import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { commonLabels } from 'app/common/common.labels';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { CategoriesStoreService } from '../../services/category-observable.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../services/snackMessage.service';
import { UtilsService } from '../../services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-card-horizontal',
  templateUrl: './card-horizontal.component.html',
  styleUrls: ['./card-horizontal.component.scss']
})
export class CardHorizontalComponent implements OnInit, OnDestroy {
  @Input() theme: string;
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() categorySuggestedId: string;
  @Input() categoryPurchased?: CategoryModel;
  @Input() categoryFeatured?: CategoryModel;
  @Input() categorySpecial?: CategoryModel;
  @Input() categoryMostPurchased?: CategoryModel;
  @Input() categorySuggested?: CategoryModel;
  @Input() showText?: boolean;
  @Input() context?: string;
  @Input() dataCategory?: any;
  @Input() _data: any;
  @Input() showButtons?: boolean = true;
  @Input() showTagsList?: boolean = true;
  @Output() moreButtonEvent = new EventEmitter<{ id: string; event: object }>();

  className = 'app-card-horizontal';
  labels: CommonLabels = commonLabels;
  purchased: boolean = false;
  mostPurchased: boolean = false;
  special: boolean = false;
  featured: boolean = false;
  menuState: string = '';
  shortDescriptionTooltip: string;
  categoriesTags: any = [];

  constructor(
    private snackMessageService: SnackMessageService,
    private categoriesStoreService: CategoriesStoreService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.categoriesStoreService.getStateMenu().subscribe(
      (state) => {
        this.menuState = state;
        this.shortDescriptionTooltip = this._data.shortDescription.replace(/<[^>]*>/g, '');
      },
      (error) => {
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    );

    this._data.categories.forEach((category) => {
      if (this.context !== '/public/') {
        if (category === this.categoryFeaturedId || category.type === 1) {
          this.featured = true;
        }
        if (category === this.categoryPurchasedId || category.type === 2) {
          this.purchased = true;
        }
        if (category === this.categoryMostPurchasedId || category.type === 3) {
          this.mostPurchased = true;
        }
        if (category === this.categorySpecialId || category.type === 4) {
          this.special = true;
        }
      }
    });
    this.checkCategoriesTags();
  }

  checkCategoriesTags() {
    this.categoriesTags = [];
    let _categoryBarbara = this._data.categories.find((cat) => cat['type'] === 4);
    let _categoryStandard = this._data.categories.find((cat) => cat['type'] === 0);
    if (_categoryBarbara !== undefined) {
      this.categoriesTags.push(_categoryBarbara);
    }
    if (_categoryStandard !== undefined) {
      this.categoriesTags.push(_categoryStandard);
    }
  }

  clickButtonMore(id: string, event: object): void {
    this.moreButtonEvent.emit({ id, event });
  }

  ngOnDestroy() {}
}