import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { navigationPublic } from 'app/navigation/navigation';
import { CommonLabels } from '../../../../common/models/common.model';
import { commonLabels } from '../../../../common/common.labels';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { UtilsService } from '../../../../common/services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-menu-public',
  templateUrl: 'menu-public.component.html',
  styleUrls: ['menu-public.component.scss']
})
export class MenuPublicComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() theme: string;
  @Input() dataCategory: any;
  @Input() showMenu? = false;
  @Output() closeMenuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openModalContactEmitter: EventEmitter<any> = new EventEmitter();

  className = 'menu';
  labels: CommonLabels = commonLabels;
  menuFixed = [];
  menuDinamic = [];
  contentMenu = [];
  option = {
    id: '',
    title: '',
    type: '',
    categoryType: 0,
    order: 0,
    externalUrl: false,
    borderTop: false,
    icon: '',
    img: '',
    url: ''
  };

  constructor(public utilsService: UtilsService) {}

  ngOnInit(): void {
    this.contentMenu = this.dataCategory;
    //console.log(this.contentMenu);
    if (this.contentMenu.length > 0) {
      this.formatMenu();
    }
  }

  formatMenu() {
    this.menuFixed = navigationPublic;
    this.menuDinamic = [];
    this.contentMenu.forEach((category) => {
      let icon = '';
      if (category.icon.includes('.svg')) {
        icon = category.icon.split('.svg')[0];
      }
      this.option = {
        id: category._id,
        title: category.name,
        order: category.order,
        icon: category.icon,
        img: icon,
        type: 'item',
        categoryType: category.type,
        externalUrl: false,
        borderTop: false,
        url: 'category/' + category._id
      };

      this.menuDinamic.push(this.option);
    });

    this.menuDinamic.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    this.contentMenu = this.menuFixed.concat(this.menuDinamic);
    //console.log(this.contentMenu);
  }

  closeMenu(): void {
    this.closeMenuEmitter.emit('closeMenu');
  }

  openModalContact($event): void {
    this.openModalContactEmitter.emit($event);
  }

  identify(index, item) {
    return index;
  }

  ngOnDestroy(): void {}
}