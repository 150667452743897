import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { commonLabels } from '../../common.labels';
import { KeycloakService } from 'keycloak-angular';
import jwtDecode from 'jwt-decode';

declare var hbspt: any;

@Component({
  selector: 'app-hubspot',
  templateUrl: './hubspot.component.html',
  styleUrls: ['./hubspot.component.scss']
})
export class HubspotComponent implements OnDestroy, OnInit {
  className = 'app-hubspot';
  theme: 'marketplace-default';
  contactUs: UntypedFormGroup;
  objectForm: any;
  userProfile;
  @Output() submitedFormEmitter = new EventEmitter();
  protected readonly commonLabels = commonLabels;

  constructor(private readonly keycloak: KeycloakService) {
    this.objectForm = [
      {
        formControl: 'firstname',
        placeholder: 'First Name',
        id: 'firstname',
        name: 'firstname',
        required: true,
        type: 'text',
        maxLength: 100,
        autocomplete: 'off'
      },
      {
        formControl: 'secondname',
        placeholder: 'Second Name',
        id: 'secondname',
        name: 'secondname',
        required: true,
        type: 'text',
        maxLength: 100,
        autocomplete: 'off'
      },
      {
        formControl: 'email',
        placeholder: 'Email',
        id: 'email',
        name: 'email',
        required: true,
        type: 'email',
        maxLength: 100,
        autocomplete: 'off'
      },
      {
        formControl: 'phone',
        placeholder: 'Phone Number',
        id: 'phone',
        name: 'phone',
        required: true,
        type: 'tel',
        maxLength: 100,
        autocomplete: 'off'
      },
      {
        formControl: 'company',
        placeholder: 'Company Name',
        id: 'company',
        name: 'company',
        required: true,
        type: 'text',
        maxLength: 100,
        autocomplete: 'off'
      }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.creteForm();

    let token = await this.keycloak.getToken();
    if (token !== undefined) {
      let tokenJwt: any = jwtDecode(token);
      this.userProfile = await this.keycloak.loadUserProfile();
      this.contactUs.patchValue({
        firstname: this.userProfile.firstName,
        secondname: this.userProfile.lastName,
        email: this.userProfile.email,
        phone: this.userProfile.attributes.phone[0],
        company: tokenJwt.companyName,
        conditions: true
      });
    }
  }

  submitForm() {
    if (this.contactUs.valid) {
      console.log('this.contactUs.value', this.contactUs.value);
      this.submitedFormEmitter.emit(this.contactUs.value);
      this.contactUs.reset();
    }
  }

  creteForm() {
    this.contactUs = new UntypedFormGroup({
      firstname: new FormControl('', [Validators.required]),
      secondname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      communications: new FormControl(false),
      conditions: new FormControl(false, [Validators.required])
    });
  }

  ngOnDestroy() {}
}