<div class="{{ className }}">
  <div class="{{ className }}__wrapper">
    <img
      alt="No data"
      class="{{ className }}__wrapper-no-data-image"
      src="assets/images/empty/{{ img }}.svg" />
    <h2 class="{{ className }}__wrapper-no-data-title">{{ title }}</h2>
    <p class="{{ className }}__wrapper-no-data-description">{{ description }}</p>
    <button
      (click)="closeMenu($event, buttonUrl)"
      [routerLink]="[buttonUrl]"
      class="big"
      color="accent"
      mat-stroked-button>
      {{ buttonText }}
    </button>
  </div>
  <div class="{{ className }}__category-suggested">
    <h3 class="{{ className }}__category-suggested-title">{{ title2 }}</h3>
    <ul class="{{ theme }} {{ className }}__category-list suggested">
      @for (item of arrayApplicationFeatured; track $index) {
        @if (times.length >= $index) {
          <li
            class="{{ theme }} {{ className }}__category-list-item col-{{
              times.length
            }} suggested">
            <app-card-vertical
              [theme]="theme"
              [isDesktop]="isDesktop"
              [width]="width"
              [categoryFeaturedId]="categoryFeaturedId"
              [categorySuggestedId]="categorySuggestedId"
              [categorySpecialId]="categorySpecialId"
              [categoryPurchasedId]="categoryPurchasedId"
              [categoryMostPurchasedId]="categoryMostPurchasedId"
              [categoryFeatured]="categoryFeatured"
              [categorySuggested]="categorySuggested"
              [categorySpecial]="categorySpecial"
              [categoryPurchased]="categoryPurchased"
              [categoryMostPurchased]="categoryMostPurchased"
              [showText]="showText"
              [showButtons]="showButtons"
              [showTagsList]="showTagsList"
              [context]="context"
              (moreButtonEvent)="onClickMoreButton(item._id)"
              [_data]="item">
            </app-card-vertical>
          </li>
        }
      }
    </ul>
  </div>
</div>